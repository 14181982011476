.header-search {
  height: 36px;
  z-index: 3;
  position: relative;

  &.p-menu-top {
    display: none;

    @media (min-width: $large) {
      display: block;
    }
  }

  &.p-menu-wrap {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);

    @media (min-width: $large) {
      display: none;
    }
  }

  &:not(.opened) {
    @extend %searchClosed;
  }

  &.opened {
    transition: width 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);

    &.p-menu-top {
      width: 250px;
    }

    &.p-menu-wrap {
      width: 230px;
    }
  }

  // Formulário de pesquisa
  &-form {
    position: relative;
    width: 100%;
    height: 36px;

    & &-input {
      width: 100%;
      height: 100%;
      padding: 8px 42px;
      box-sizing: border-box;
      border-radius: 24px;
      background: $white;
      color: $grey;
      border: none;
      transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
      line-height: 20px;
      min-height: auto;
      appearance: none;

      &[type='search']::-webkit-search-decoration,
      &[type='search']::-webkit-search-cancel-button,
      &[type='search']::-webkit-search-results-button,
      &[type='search']::-webkit-search-results-decoration {
        appearance: none;
        -webkit-appearance: none;
      }

      &:focus {
        outline: none;
        box-shadow: none;
      }

      &::placeholder {
        color: $greySoftDark;
      }
    }

    &-button {
      width: 25px;
      height: 25px;
      background: transparent;
      padding: 0;
      border: none;
      transform: translateY(-50%) scaleX(-1);
      position: absolute;
      top: 49%;
      cursor: pointer;
      transition: right 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);

      &.-search {
        .icon-search {
          font-size: 25px;
          color: $primary;
        }
      }

      &.-close {
        padding: 4.5px;

        .icon-close {
          font-size: 16px;
          color: $grey;
        }
      }
    }

    &.p-menu-top {
      .header-search-form-button {
        &.-search {
          left: 12px;
        }
        &.-close {
          right: 12px;
        }
      }
    }

    &.p-menu-wrap {
      .header-search-form-button {
        &.-search {
          right: 12px;
        }
        &.-close {
          left: 12px;
        }
      }
    }
  }

  // Resultados da pesquisa
  &-results {
    transition: opacity 0.5s cubic-bezier(0.075, 0.82, 0.165, 1) 0.5s;
    box-shadow: 0 0 10px 0 rgba($black, 0.16);
    position: absolute;
    top: 100%;
    margin-top: 3px;

    &.p-menu-top {
      width: 225px;
      left: 50%;
      transform: translateX(-50%);
    }

    &.p-menu-wrap {
      right: 0;
      width: 275px;
    }

    .search-card {
      .search-card-title {
        width: 100%;
        display: block;
      }

      &.suggestions {
        @extend %background;
        @extend %suggestionsCard;
      }

      &.empty {
        @extend %background;
      }

      &.content {
        @extend %content;
      }

      &.loading {
        @extend %loading;
      }
    }
  }
}

.search-related {
  .Typography {
    display: inline-block;
    width: fit-content;
    margin-right: 10px;
    font-weight: 700;
  }

  a {
    display: inline-block;
    color: $black;
    font-size: $size-12;
    text-decoration: underline;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}

// Busca fechada
%searchClosed {
  width: 25px;
  transition: width 0.5s cubic-bezier(0.075, 0.82, 0.165, 1) 0.1s;

  &.p-menu-wrap {
    &::before {
      content: '';
      position: absolute;
      right: -20px;
      top: 50%;
      transform: translateY(-50%);
      height: 50px;
      width: calc(100% + 60px);
      opacity: 0.9;
      background: linear-gradient(to left, $white 70%, transparent 100%);
    }
  }

  .header-search {
    &-form {
      &-input {
        opacity: 0;
        width: 0;
        padding: 0;
        pointer-events: none;
        transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.1s;
      }

      &-button {
        .icon-search {
          @media (min-width: $large) {
            color: $white;
          }
        }

        &.-close {
          display: none;
        }
      }

      &.p-menu-top {
        .header-search-form-button {
          &.-search {
            left: 0;
          }
        }
      }

      &.p-menu-wrap {
        .header-search-form-button {
          &.-search {
            right: 0;
          }
        }
      }
    }

    &-results {
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.1s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
  }
}

// Background dos cards
%background {
  background: $white;
  padding: 8px 16px;
  width: 100%;
  box-sizing: border-box;
}

// Card de sugestões
%suggestionsCard {
  &:not(:last-child) {
    margin-bottom: 2px;
  }

  .search-card-list {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: 15px;
    row-gap: 5px;
    flex-wrap: wrap;
    color: $black;
  }
}

// Card de resultados
%content {
  &:not(:last-child) {
    margin-bottom: 2px;
  }

  .search-card-list {
    width: 100%;
    color: $black;

    li {
      margin-bottom: 0;

      &:not(:last-child) {
        margin-bottom: 2px;
      }

      a {
        &:hover {
          font-weight: 700;
        }

        @extend %background;
      }
    }
  }
}

// Loading
%loading {
  background: $white;
  height: 180px;
  width: 100%;
  position: relative;
  z-index: 9;

  &::after {
    content: '';
    z-index: 9;
    animation: loading 1.5s infinite linear;
    height: 50px;
    width: 50px;
    border-top: 3px solid $primary;
    border-right: 3px solid $primary;
    border-bottom: 3px solid $primary;
    border-left: 3px solid transparent;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
  }

  @keyframes loading {
    from {
      transform: translate(-50%, -50%) rotate(0);
    }
    to {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
}
