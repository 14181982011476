.BannerWithImage {
  background-color: $primaryAnil;
  padding-top: 58px;
  padding-bottom: 45px;
  font-family: $poppins;

  .BannerWithImage__Title {
    font-weight: 600;
    font-size: $size-32;
    line-height: 41.5px;
    color: #ececec;
    max-width: 280px;
  }

  .BannerWithImage__Description {
    color: #ececec;
    font-size: $size-16;
    line-height: 28.8px;
    font-weight: 400;
    max-width: 280px;
  }

  .BannerWithImage__Image {
    display: none;
  }

  @media (min-width: $tablet) {
    position: relative;

    .Container {
      display: flex;
      justify-content: space-between;
    }

    .BannerWithImage__Image {
      display: block;
      object-fit: cover;
      width: 323px;
      position: absolute;
      right: 20px;
      top: 11%;
    }
  }

  @media (min-width: $container) {
    padding-top: 110px;
    padding-bottom: 55px;

    .Container {
      padding: 0;
    }

    .BannerWithImage__Title {
      font-size: $size-34;
      line-height: 41.5px;
      max-width: 450px;
    }

    .BannerWithImage__Description {
      font-size: $size-28;
      line-height: 38.8px;
      max-width: 430px;
    }

    .BannerWithImage__Image {
      position: relative;
      margin-top: -75px;
    }
  }
}
