/* stylelint-disable */
.select2 {
  max-width: 100%;
  width: 100% !important;

  .selection .select2-selection {
    width: 100%;
    height: 60px;
    border-radius: 10px !important;
    font-size: $size-14;
    font-weight: 400;
    letter-spacing: 0.45px;
    border: 2px solid $primaryAnil;

    .select2-selection__rendered {
      padding: 0 42px 0 18px;
      height: 58px;
      display: flex;
      font-size: $size-14;
      align-items: center;
    }

    .select2-selection__arrow {
      padding: 28px;
    }
  }
}

.select2-container {
  .select2-results__message {
    position: absolute !important;
    background: #fff;
    top: -2px;
    height: 125%;
    width: 105%;
    left: -1px;
    font-size: 0;
    border-radius: 10px !important;
    padding: 1px;
  }
}

.select2-container--open .select2-dropdown {
  margin-top: 5px;
}

.select2-search.select2-search--dropdown {
  display: none;
}

.select2-container--open .select2-dropdown--below {
  border-radius: 10px !important;
  border-top: 1px solid #aaa !important;
}

.select2-container--default .select2-results__option .select2-results__option,
.select2-results .select2-results__option {
  padding: 0;
  position: relative;
}

.select2-container--default .select2-results > .select2-results__options {
  padding: 10px;
}
