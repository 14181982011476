.IconSocial {
  border-radius: 100px;
  width: 40px;
  height: 40px;
  border: 1px solid #015580;
  display: inline-block;
  position: relative;
  font-size: $size-22;
  color: #015580;

  &.icon-social-linkedin {
    font-size: 30px;
  }

  &:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.icon-twitter {
    &:before {
      content: '';
      width: 24px;
      height: 24px;
      display: block;
      background: url('../images/twitter.svg') no-repeat;
    }
  }
}
