.Typography {
  display: block;
  width: 100%;
  text-rendering: optimizeSpeed;
  color: inherit;

  strong {
    word-break: unset;
  }

  & > a {
    font-size: inherit;
  }

  // Heading TAGs Sizes
  &.title {
    &-1 {
      @extend %title-1;
      @extend %strong-bold;
    }

    &-2 {
      @extend %title-2;
      @extend %strong-bold;
    }

    &-3 {
      @extend %title-3;
      @extend %strong-bold;
    }

    &-4 {
      @extend %title-4;
      @extend %strong-semibold;
    }

    &-5 {
      @extend %title-5;
      @extend %strong-bold;
    }

    &-6 {
      @extend %title-6;
      @extend %strong-bold;
    }
  }

  &.subtitle {
    @extend %subtitle;
    @extend %strong-bold;
  }

  &.subtitle-small {
    @extend %subtitle-small;
    @extend %strong-bold;
  }

  &.text {
    @extend %body;
    @extend %strong-bold;
  }

  &.btn {
    font-weight: 400;
    font-size: inherit;
    @extend %TypoButton;
    @extend %strong-bold;
  }

  &.link:not(.btn) {
    font-weight: 400;

    @extend %link;
    @extend %strong-bold;
  }

  &.caption {
    font-weight: 400;

    @extend %caption;
    @extend %strong-bold;
  }

  // Alinhamentos
  @extend %aligns;
}

%strong-semibold {
  strong,
  b {
    font-weight: 600;
  }
}

%strong-bold {
  strong,
  b {
    font-weight: 700;
  }
}

// Padrão para H1
%title-1 {
  font-weight: 500;
  font-size: $size-40;
  line-height: $line-72;

  @media (min-width: $large) {
    font-size: $size-56;
    line-height: $line-96;
  }
}

// Padrão para H2
%title-2 {
  font-weight: 500;
  font-size: $size-32;
  line-height: $line-48;

  @media (min-width: $large) {
    font-size: $size-48;
    line-height: $line-84;
  }
}

// Padrão para H3
%title-3 {
  font-weight: 500;
  font-size: $size-28;
  line-height: $line-48;

  @media (min-width: $large) {
    font-size: $size-40;
    line-height: $line-66;
  }
}

// Padrão para H4
%title-4 {
  font-weight: 500;
  font-size: $size-24;
  line-height: $line-42;

  @media (min-width: $large) {
    font-size: $size-32;
    line-height: $line-48;
  }
}

// Padrão para H5
%title-5 {
  font-weight: 500;
  font-size: $size-22;
  line-height: $line-28;

  @media (min-width: $large) {
    font-size: $size-24;
    line-height: $line-42;
  }
}

// Padrão para H6
%title-6 {
  font-weight: 500;
  font-size: $size-16;
  line-height: $line-28;

  @media (min-width: $large) {
    font-size: $size-20;
    line-height: $line-36;
  }
}

// Padrão Subtitulos
%subtitle {
  padding: 0;
  font-size: $size-14;
  line-height: $line-24;

  @media (min-width: $large) {
    font-size: $size-18;
    line-height: $line-28;
  }
}

// Padrão Subtitulos pequenos
%subtitle-small {
  padding: 0;
  font-size: $size-12;
  line-height: $line-20;
}

// Padrão para textos
%body {
  font-weight: 400;
  font-size: $size-14;
  line-height: $line-24;

  a:not(.has-text-color a) {
    color: $primaryAnil;

    &:hover {
      text-decoration: underline;
    }
  }

  @media (min-width: $large) {
    font-size: $size-16;
    line-height: $line-28;
  }
}

// Padrão para botões
%TypoButton {
  font-size: $size-16;
  line-height: $line-28;
}

// Padrão para links
%link {
  font-size: $size-16;
  line-height: $line-18;
  text-decoration: underline;
  font-weight: 400;
}

// Padrão para captions
%caption {
  font-size: $size-12;
  line-height: $line-20;
}

//Alinhamentos
%aligns {
  &.center {
    text-align: center;
  }
  &.left {
    text-align: left;
  }
  &.right {
    text-align: right;
  }
  &.left-center {
    text-align: left;

    @media (min-width: $large) {
      text-align: center;
    }
  }

  &.center-left {
    text-align: center;

    @media (min-width: $large) {
      text-align: left;
    }
  }
}
