/* stylelint-disable */
.Footer {
  background: #f2f2f2;
  font-size: 14px;

  &-container {
    padding: 20px;

    .DownloadApp {
      margin-bottom: 30px;
      justify-content: center;

      .DownloadButton {
        border: none;
        margin-top: 10px;
        background: transparent;

        a {
          border: 1px solid #0069b3;
          border-radius: 30px;
          padding: 8px 15px;
          font-size: $size-12;
          line-height: $line-18;
          color: $primaryAnil;
          display: flex;
          align-items: center;

          &:first-child {
            margin-right: 16px;
          }

          img {
            margin-right: 5px;
          }
        }
      }

      &-title {
        margin-top: 0;
        font-size: $size-14;
        line-height: $line-22;
        font-weight: 600;
        letter-spacing: 0.75px;
        color: $black;
      }
    }
  }

  &-horizontalList {
    justify-content: center;
    margin-bottom: 12px;

    a {
      margin: 0 !important;
      font-size: 0;
    }

    ul {
      display: flex;
      gap: 10px;
    }
  }

  &-menu ul:not(.sub-menu) > li > a {
    display: block;
    font-weight: 700;
    margin-bottom: 18px;
  }

  .sub-menu {
    margin-bottom: 20px;

    li {
      margin-bottom: 13px;
    }
  }

  &-branding {
    margin-bottom: 16px;
    text-align: center;
    padding-bottom: 8px;
    border-bottom: 1px solid #cecece;
  }

  &-horizontalList {
    justify-content: center;
    margin-bottom: 12px;

    ul {
      display: flex;
      gap: 10px;
    }

    a {
      margin: 0 !important;
      font-size: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 100px;
    }
  }

  &-copyright {
    display: block;
    text-align: center;
  }

  .desk {
    display: none;
  }

  .mobile {
    display: flex;
  }

  &-tel {
    flex-direction: column;

    &-title {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 0px;
      color: $black;
    }

    &-number {
      margin-bottom: 30px;
    }
  }

  &-stamps {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
    margin: 20px 0;

    img {
      height: 45px;
      width: auto;

      @media (min-width: $tablet) {
        height: 60px;
      }
    }
  }

  @media (min-width: $large) {
    &-container {
      padding: 30px 0;
      display: flex;
      flex-direction: column;
      position: relative;
    }

    &-links {
      flex: 1;

      ul {
        li {
          margin-right: 100px;

          ul {
            li {
              margin-right: 0;
            }
          }
        }
      }
    }

    &-branding {
      margin: 0 10% 24px 0;
      border: 0;
      text-align: left;
      order: 1;
    }

    &-menu {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin: 0 10% 15px 0;
      order: 2;

      &-medias {
        flex-direction: column;

        .DownloadApp {
          margin-top: 30px;

          .DownloadButton {
            border: none;
            margin-top: 10px;

            a {
              border: 1px solid #0069b3;
              border-radius: 30px;
              padding: 8px 15px;
              font-size: $size-12;
              line-height: $line-18;
              color: $primaryAnil;
              display: flex;
              align-items: center;

              &:first-child {
                margin-right: 16px;
              }

              img {
                margin-right: 5px;
              }
            }
          }
        }

        &-title {
          margin-top: 0;
          font-size: $size-14;
          line-height: $line-22;
          font-weight: 600;
          letter-spacing: 0.75px;
          color: $black;
        }
      }
    }

    &-copyright {
      width: 100%;
      order: 3;
    }

    &-menu ul:not(.sub-menu) {
      display: flex;
      gap: 10px;
    }

    &-horizontalList {
      width: 240px;
    }

    .desk {
      display: flex;
    }

    .mobile {
      display: none;
    }

    &-tel-number {
      margin-bottom: 0;
    }
  }
}

.Fab-wrapper .icon-chat:before {
  content: '';
  width: 42px;
  height: 44px;
  background: url('../../theme/images/icon-chat.svg') no-repeat;
}

.Fab-checkbox:checked ~ .Fab-wrapper .icon-chat:before {
  background: none;
  width: 25px;
  height: 25px;
}

.icon.icon-chat:before {
  content: '';
  width: 42px;
  height: 44px;
  background: url('../../theme/images/icon-chat.svg') no-repeat;
  transform: scale(0.6);
}
