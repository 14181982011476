/* stylelint-disable */

.HeaderMenu {
  position: relative;

  .menu {
    color: $primaryAnil;
    display: flex;
    align-items: center;
    padding: 14px 80px 14px 20px;
    font-size: $size-12;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: visible;

    &::-webkit-scrollbar {
      display: none;
    }

    .sub-menu {
      display: block;
      overflow: hidden;
      height: 0;
      position: absolute;
      top: 35px;
      background-color: $white;
      z-index: 3;
      transition: all 0.3s ease;

      &:before {
        content: '';
        display: block;
        background: rgba($white, 0.01);
        z-index: -1;
        margin-top: -1px;
        height: 10px;
        width: 100%;
      }

      &--active {
        height: 100%;
        overflow: visible;
        transition: all 0.3s ease;
      }

      .menu-item {
        position: relative;
        margin: 0;
        box-shadow: 0 1px 3px rgba($black, 0.26);
        width: 150%;
        max-width: 200px;

        @media (min-width: $tablet) {
          width: 100%;
          min-width: 257px;
        }

        a {
          display: block;
          background-color: $white;
          width: 100%;
          padding: 6px 10px;
          font-size: $size-12;
          line-height: $line-36;
          color: $primaryAnil;

          &::before {
            content: none;
          }
        }

        &:not(:last-child) {
          a {
            border-bottom: 1px solid $primaryAnil;
          }
        }

        &:hover {
          a {
            background: $primaryAnil;
            color: $white;
          }
        }
      }

      @media (min-width: $large) {
        top: 44px;
      }
    }

    > .menu-item {
      outline: 0 none;
      flex: 0 0 auto;
      margin: 0;

      &:not(:last-child) {
        margin-right: 20px;
        padding-bottom: 3px;
      }

      &:focus {
        outline: none;
        box-shadow: none;
      }

      &.current_page_item {
        font-weight: 700 !important;
      }

      a {
        position: relative;
        z-index: 2;

        &:before {
          content: '';
          height: 0;
          top: 100%;
          left: 0;
          width: 0;
          display: block;
          transition: width 0.6s;
          background: $primaryAnil;
          border-radius: 28px;
          position: absolute;
          pointer-events: none;
        }
      }

      &:hover,
      &.current_page_item {
        &:not(.btn-menu) {
          a:before {
            width: 100%;
            height: 2px;

            @media (min-width: $tablet) {
              height: 3px;
            }
          }
        }
      }
    }

    .menu-item-has-children {
      > a {
        border-bottom: 0;
      }

      .sub-menu {
        cursor: pointer;
      }

      &.active {
        .sub-menu {
          height: auto;
          overflow: visible;
          transition: all 0.3s ease;
        }
      }
    }

    .btn-menu {
      padding-top: 0 !important;
      margin-left: auto;

      a {
        padding: 3px 7px !important;
        border-color: $primary !important;
        text-align: center;

        &:before {
          width: 0px !important;
          height: 0px !important;
        }

        &:hover {
          background: $primary;
          color: $white;

          &:before {
            display: none;
          }
        }
      }
    }
  }

  @media (min-width: $large) {
    .menu {
      font-size: $size-16;
      overflow: visible;
      padding: 14px 0;

      > .menu-item {
        &:not(:last-child) {
          margin-right: 32px;
        }
      }

      .btn-menu {
        padding-top: 0 !important;
        font-size: $size-14;

        a {
          padding: 8px 15px !important;
          border-color: $primary !important;
        }
      }
    }
  }
}