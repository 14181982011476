body {
  background-color: #fbfffd;
  font-family: $poppins;
}

html {
  scroll-behavior: smooth;
}

// Margem entre componentes
.margin {
  &-none {
    margin: 0;
  }

  &-micro {
    margin: 0 0 $marginMicro;

    @media (min-width: $large) {
      margin: 0 0 $marginExtraSmall;
    }
  }

  &-extra-small {
    margin: 0 0 $marginExtraSmall;

    @media (min-width: $large) {
      margin: 0 0 $marginBase;
    }
  }

  &-small {
    margin: 0 0 $marginSmall;

    @media (min-width: $large) {
      margin: 0 0 $marginMedium;
    }
  }

  &-base {
    margin: 0 0 $marginBase;

    @media (min-width: $large) {
      margin: 0 0 $marginLarge;
    }
  }

  &-medium {
    margin: 0 0 $marginMedium;

    @media (min-width: $large) {
      margin: 0 0 $marginExtraLarge;
    }
  }

  &-large {
    margin: 0 0 $marginLarge;

    @media (min-width: $large) {
      margin: 0 0 $marginHuge;
    }
  }

  &-huge {
    margin: 0 0 $marginExtraLarge;

    @media (min-width: $large) {
      margin: 0 0 $marginExtraHuge;
    }
  }

  //Espaçamentos entre seções
  &-section-extra-small {
    margin: 0 0 $marginBase;
  }
  &-section-small {
    margin: 0 0 $marginLarge;
  }
  &-section-base {
    margin: 0 0 $marginHuge;
  }
  &-section-large {
    margin: 0 0 $marginExtraHuge;
  }
}

// Cores de fundo
.bg {
  &-white {
    color: $white;
    background: currentColor;
  }
  &-black {
    color: $black;
    background: currentColor;
  }
  &-blue {
    color: $primary;
    background: currentColor;
  }
  &-blue-light {
    color: $primaryLight;
    background: currentColor;
  }
  &-blue-anil {
    color: $primaryAnil;
    background: currentColor;
  }
  &-blue-dark {
    color: $primaryDark;
    background: currentColor;
  }
  &-anil-dark {
    color: $primaryDarker;
    background: currentColor;
  }
  &-orange {
    color: $secondary;
    background: currentColor;
  }
  &-green-light {
    color: $tertiaryMedium;
    background: currentColor;
  }
  &-grey {
    color: $grey;
    background: currentColor;
  }

  &-gradient {
    color: $primary;
    background: currentColor;
    background: linear-gradient(
      0deg,
      $primary 0%,
      $primaryLight 50%,
      $primary 100%
    );
  }

  &-gradient2 {
    color: $primaryLight;
    background: currentColor;
    background: linear-gradient(
      0deg,
      $primaryLight 0%,
      $primary 50%,
      $primaryLight 100%
    );
  }

  &-gradient3 {
    color: $primaryLight;
    background: currentColor;
    background: linear-gradient(90deg, $primaryLight 0%, $primary 100%);
  }

  &-gradient4 {
    color: $primary;
    background: currentColor;
    background: linear-gradient(90deg, $primary 0%, $primaryLight 100%);
  }

  &-gradient5 {
    color: $fourth;
    background: currentColor;
    background: linear-gradient(
      270.16deg,
      $fourth 0%,
      $fourthMedium 31.12%,
      $fourthDark 59.59%
    );
  }
}

// Cores de fontes
.color {
  &-black {
    color: $black;
  }

  &-white {
    color: $white;
  }

  &-blue {
    color: $primary;
  }

  &-blue-dark {
    color: $primaryDark;
  }

  &-grey {
    color: $grey;
  }

  &-grey-dark {
    color: $greyDark;
  }

  &-orange {
    color: $secondaryLighten;
  }
}

.d-none {
  display: none;
}
