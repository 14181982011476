button {
  outline: none !important;
}

* {
  list-style: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0;
  // backface-visibility: hidden;
}

:root {
  --swiper-navigation-size: 12px !important;
}

::placeholder {
  color: inherit;
  opacity: 1;
}

img {
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

.bdr {
  border-radius: 20px;
}

strong {
  font-weight: bold;
}

.reset-list {
  list-style-type: none;
  padding-left: 0;
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}
