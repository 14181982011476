.Header {
  position: relative;
  z-index: 3;

  &-top {
    position: relative;
    z-index: 99;
    background: $primaryAnil;
  }

  &-container {
    padding: 21px 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (min-width: $large) {
      &::before {
        content: '';
        z-index: 2;
        position: fixed;
        width: 100vw;
        height: 100vh;
        pointer-events: none;
        top: 0;
        left: 0;
        background: rgba($black, 0.4);
        opacity: 0;
        transition: opacity 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
      }
    }
  }

  &-branding {
    max-width: 100px;

    img {
      width: auto;
    }

    h1 {
      margin: 0;
    }
  }

  &-iconMenu {
    font-size: $size-33;
    color: $white;
  }

  &-menuWrap {
    background: $white;
    color: $primary;
    position: relative;

    &::before {
      content: '';
      z-index: 2;
      position: fixed;
      width: 100vw;
      height: 100vh;
      pointer-events: none;
      top: 0;
      left: 0;
      background: rgba($black, 0.4);
      opacity: 0;
      transition: opacity 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    .Container {
      padding: 0;
    }
  }

  .btn-menu {
    padding-top: 20px;

    a {
      padding: 12px 22px;
      display: inline-block;
      border-radius: 100px;
      border: 1px solid $white;
      text-align: center;
    }
  }

  &-itemsRight {
    color: $white;
    font-size: $size-14;
    display: flex;
    align-items: center;
    gap: 46px;

    i {
      font-style: normal;
      font-weight: 500;
      font-family: $poppins !important;

      &:before {
        font-family: 'icomoon', serif !important;
      }
    }
  }

  &-city {
    font-weight: 400;
    display: flex;
    align-items: center;
    cursor: pointer;
    transform: translateY(1px);

    cite {
      text-overflow: ellipsis;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
      font-style: normal;
    }

    i {
      font-size: 20px;
    }
  }

  &.search-opened {
    .Header-top {
      z-index: 2;

      .Header-container {
        @media (max-width: $container) {
          .Header-itemsRight {
            gap: 25px;
          }
        }

        @media (min-width: $large) {
          &::before {
            pointer-events: all;
            opacity: 1;
          }
        }
      }

      @media (min-width: $large) {
        z-index: 3;
      }
    }

    @media (max-width: ($large - 1px)) {
      .Header-menuWrap {
        &::before {
          pointer-events: all;
          opacity: 1;
        }
      }
    }
  }

  // none view
  &-bill,
  &-tell,
  &-topLink {
    display: none;
    cursor: pointer;
  }

  @media (min-width: $large) {
    &-branding {
      max-width: none;
    }

    &-container {
      display: flex;
      align-items: center;
      padding: 21px 0;
      justify-content: space-between;
    }

    &-iconMenu {
      display: none;
    }

    &-bill,
    &-tell,
    &-topLink {
      display: block;
    }

    &-city {
      font-weight: 700;
      text-rendering: geometricprecision;
    }

    &-menuWrap {
      background: $white;
    }
  }
}
