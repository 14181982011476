/* stylelint-disable */
.Fab {
  position: fixed;
  bottom: 3rem;
  right: 3rem;
  z-index: 3;

  &-close {
    top: -65%;
    position: absolute;
    left: 2%;
    transform: translate(-50%, -100%);
    cursor: pointer;
    font-size: $size-16;
    color: black;
    border: 1px solid black;
    border-radius: 50%;
  }

  &-checkbox-help-text {
    margin-right: 35px;
    -webkit-transition: 0.2s ease-out;
    -moz-transition: 0.2s ease-out;
    transition: 0.2s ease-out;
    top: -10px;

    span {
      font-size: $size-10;
      line-height: 18px;
      font-weight: 500;
      pointer-events: none;
      opacity: 1;
      color: $grey;
      position: absolute;
      top: 42%;
      left: 38%;
      transform: translate(-50%, -50%);
      text-align: center;
    }
  }

  #fabCheckbox-help {
    margin-right: 22px;
    position: relative;
    top: 0.9rem;

    @media (max-width: $tablet) {
      display: none;
    }
  }

  #fabCheckbox-help img {
    width: 150px;
    height: 61px;
  }

  #fabCheckbox {
    display: none;
  }

  &-checkbox:checked &-checkbox-help-text {
    display: none;
  }
  &-checkbox:checked ~ &-wheel &-action {
    opacity: 1;
  }

  &-checkbox:checked ~ .cosmobots-pop {
    display: inline !important;
  }

  &-action:hover {
    color: $white;
    background: $primaryDark;
    box-shadow: 0px 5px 20px 5px #81a4f1;
  }

  &-checkbox:checked ~ &-wheel {
    transform: scale(1);
  }

  &-checkbox:checked ~ &-wrapper {
    background-color: #939393;
    width: 45px;
    height: 45px;
    transition: all 0.3s ease;

    .icon-chat::before {
      content: '\e903';
      transition: all 0.3s ease;
      font-size: $size-24;
    }
  }

  &-wrapper {
    position: absolute;
    bottom: -1rem;
    right: -1rem;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: $primaryAnil;
    box-shadow: 3px 6px 3px $greyDark;
    transition: all 0.3s ease;
    z-index: 1;
    border: 2px solid #fff;

    @media (max-width: $mobile-large) {
      right: -2rem;
    }

    .icon-chat {
      display: flex;
      font-size: $size-44;
      justify-content: center;
      width: 100%;
      height: 100%;
      align-items: center;
    }

    &:hover {
      color: $white;
      background: $primaryDark;
      box-shadow: 0px 5px 20px 5px #81a4f1;
    }

    @keyframes blink {
      50% {
        opacity: 0.25;
      }
    }
  }

  &-wheel {
    position: absolute;
    bottom: 0;
    right: 0;
    border: 1px solid transparent;
    width: 8rem;
    height: 8rem;
    transition: all 0.3s ease;
    transform-origin: bottom right;
    transform: scale(0);
  }

  &-action {
    position: absolute;
    background: $primaryAnil;
    width: 51px;
    height: 51px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: White;
    border: 2px solid #fff;
    box-shadow: 0 0.1rem 1rem rgba(24, 66, 154, 0.82);
    transition: all 0.5s ease;
    opacity: 0;

    .icon-chat {
      transform: rotate(12deg);
    }

    .icon {
      font-size: $size-22;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &-action-whatsapp {
    right: -1rem;
    top: -2rem;
  }

  &-action-phone {
    right: 3rem;
    top: 1.5rem;
    cursor: pointer;
  }

  &-contact {
    width: 203px;
    height: 90px;
    background-color: $greyLightDark;
    position: relative;
    left: -185px;
    top: -56px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    align-content: center;
    justify-content: center;
    border-bottom-right-radius: 0px;
    gap: 5px;

    @media (max-width: $mobile-large) {
      width: 167px;
      left: -141px;
    }

    & &-text {
      font-size: $size-14;
      line-height: 21px;
      font-weight: 700;
      width: 100%;
      color: $primaryAnil;
    }

    & &-number {
      font-size: $size-12;
      line-height: 18px;
      font-weight: 500;
      color: $greyBold;
      width: 100%;

      span {
        font-weight: 700;
      }
    }
  }

  &-chat {
    left: -1.5rem;
    bottom: -1rem;
    cursor: pointer;
  }

  &-iframe-wrapper {
    width: 400px;
  }

  &-title-iframe {
    position: relative;
    z-index: 2;
    top: -19.7rem;
    left: -12rem;
    color: #fff;
    font-size: $size-14;
    font-weight: 500;

    @media (max-width: $mobile-large) {
      top: -19.4rem;
      left: -10rem;
    }
  }

  &-chat-close,
  &-chat-minimize {
    position: relative;
    left: -5.5rem;
    top: -19.5rem;
    z-index: 2;
    color: #fff;
    font-size: $size-28;
    cursor: pointer;

    @media (max-width: $mobile-large) {
      left: -4.5rem;
      top: -19.1rem;
    }
  }

  &-iframe-chat {
    height: 400px;
    left: -13rem;
    position: relative;
    bottom: 22rem;
    display: none;

    iframe {
      position: relative;
      height: 400px;
    }

    @media (max-width: $mobile-large) {
      width: 290px;
      bottom: 21.5rem;
      left: -11rem;
    }
  }

  .hidden {
    display: none;
  }
}
