.Button,
.gform_wrapper .button {
  background: radial-gradient(
    50% 50% at 50% 50%,
    $secondaryLighten 0%,
    $secondaryLigh 100%
  );
  border-radius: 27px;
  color: $white;
  font-size: $size-14;
  font-weight: 700;
  padding: 16px 45px;
  display: inline-block;
  text-decoration: none;

  &-center {
    display: table;
    margin: 0 auto;
  }

  &:hover {
    background: radial-gradient(
      50% 50% at 50% 50%,
      $secondaryLighten 0%,
      $secondaryDark 100%
    );
    color: $white;
  }
}
