/* stylelint-disable */
.ModalForm {
  li {
    margin-bottom: 24px;
  }

  .gform_title {
    display: none;
  }

  &-show {
    visibility: visible;
    opacity: 1;

    .Modal-content {
      top: 45%;
    }
  }

  .gform_description {
    margin-bottom: 20px;
    display: block;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
  }

  & &-content {
    padding: 0;
    top: 50%;
    max-height: 80%;
    overflow: auto;
  }

  &-wrapForm {
    padding: 14px 14px 34px 14px;
  }

  & &-title {
    font-size: $size-14;
    color: #424242;
    letter-spacing: 0.3px;
    margin-bottom: 0;
    border: 1px solid #d3d3d3;
    text-align: left;
    padding: 16px 24px 10px 24px;
    line-height: 21px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;

    &.hide {
      display: none;
    }
  }

  & &-close {
    position: static;
  }

  .checkform {
    width: 60px;
    height: 60px;
  }

  .svg-container {
    text-align: center;
    margin-bottom: 25px;
    margin-top: 30px;
  }

  .gform_confirmation_message {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
    padding: 0 30px;
    color: $primaryAnil;

    .Modal-close {
      position: absolute;
      top: 0;
      right: 15px;
      color: #3c434a;
      cursor: pointer;
      font-weight: 400;
      font-size: 30px;
      text-rendering: geometricPrecision;
    }

    .Button {
      font-size: 16px;
      line-height: 24px;
      margin-top: 25px;
      line-height: 120%;
      min-width: 200px;
      cursor: pointer;
    }
  }

  @media (min-width: $large) {
    & &-title {
      font-size: $size-16;
      padding: 16px 24px;
      text-rendering: geometricPrecision;
    }

    .gform_confirmation_message {
      font-size: 32px;
      line-height: 48px;
      padding: 0 50px;
    }

    .checkform {
      width: 80px;
      height: 80px;
    }

    &-wrapForm {
      padding: 20px 26px;
    }

    & &-content {
      max-height: 95%;
    }
  }
}
