/* stylelint-disable */

.HeaderMenuFix {
  background: $primaryAnil;
  position: absolute;
  top: 98%;
  left: 0;
  width: 100%;
  z-index: 3;
  display: none;
  font-size: $size-14;
  color: $white;

  &--isActive {
    display: block;

    @media (min-width: $large) {
      display: none;
    }
  }

  .menu {
    padding: 16px;

    > .menu-item {
      a {
        padding: 16px 0 18px 0;
        display: block;
        border-bottom: 1px solid $white;
      }

      &:first-child {
        a {
          padding-top: 23px;
          border-top: 1px solid $primaryLight;
        }
      }
    }

    .sub-menu {
      overflow: hidden;
      padding: 0;
      height: 0;

      &--active {
        height: auto;
        overflow: visible;
      }

      .menu-item {
        background-color: $white;
        color: $primaryAnil;
        margin-bottom: 2px;
        position: relative;

        a {
          padding: 11.5px 0 6.5px 20px;
          font-size: $size-12;
          font-weight: 500;

          &::before {
            content: none;
          }
        }

        &:first-child::before {
          content: '';
          width: 14px;
          height: 14px;
          background-color: $white;
          position: absolute;
          left: 10px;
          top: -5px;
          transform: rotate(45deg);
          &:hover {
            background-color: $primaryDark;
          }
        }

        &:hover {
          background-color: $primaryDark;
          color: $white;
        }
        &:first-child:hover::before {
          background-color: $primaryDark;
        }
      }
    }
  }

  .menu-item-has-children {
    position: relative;

    > a {
      border-bottom: 0;
      pointer-events: none;
    }

    .sub-menu {
      cursor: pointer;
    }
  }
}