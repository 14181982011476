/* stylelint-disable */
.gform_wrapper {
  & input,
  & textarea {
    width: 100%;
    height: 40px;
    padding: 15px;
    border: 1px solid #d0d0d0;
    box-sizing: border-box;
    border-radius: 5px;
    resize: none;
    font-style: normal;
    font-weight: 400;
    text-rendering: geometricPrecision;
    font-size: 14px;
    line-height: 26px;
    color: #424242;
    text-transform: none !important;
    transition: 0.3s;

    &.medium {
      width: 100%;
    }

    &:focus {
      border: 1px solid $primaryAnil;
    }
  }

  & .gform_footer {
    text-align: center;
  }

  & .gform_body label {
    margin-bottom: 0;
  }

  & .gform_footer .button {
    background: radial-gradient(
      50% 50% at 50% 50%,
      $secondaryLighten 0%,
      $secondaryLigh 100%
    );
    border-radius: 27px;
    color: $white;
    font-size: $size-16;
    font-weight: 700;
    margin-top: 0;
    display: inline-block;
    text-decoration: none;
    max-width: 70%;
    line-height: 120%;
    margin: 0 auto;

    &:focus {
      border: 1px solid #d0d0d0;
    }

    &:hover {
      background: radial-gradient(
        50% 50% at 50% 50%,
        $secondaryLighten 0%,
        $secondaryDark 100%
      );
      color: $white;
    }
  }

  textarea {
    height: 100px;
    padding: 14px;
  }

  .gform_ajax_spinner {
    margin-left: 20px;
  }

  @media (min-width: $large) {
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    li {
      width: 100%;
      margin-bottom: 16px;
    }

    .medium {
      width: 48%;
    }

    textarea {
      height: 173px;
    }
  }
}

@supports (animation: grow 0.5s cubic-bezier(0.25, 0.25, 0.25, 1) forwards) {
  .tick {
    stroke-opacity: 0;
    stroke-dasharray: 29px;
    stroke-dashoffset: 29px;
    animation: draw 0.5s cubic-bezier(0.25, 0.25, 0.25, 1) forwards;
    animation-delay: 0.6s;
  }

  .circle {
    fill-opacity: 0;
    stroke: $tertiaryLight;
    stroke-width: 16px;
    transform-origin: center;
    transform: scale(0);
    animation: grow 1s cubic-bezier(0.25, 0.25, 0.25, 1.25) forwards;
  }
}

@keyframes grow {
  60% {
    transform: scale(0.8);
    stroke-width: 4px;
    fill-opacity: 0;
  }
  100% {
    transform: scale(0.9);
    stroke-width: 8px;
    fill-opacity: 1;
    fill: $tertiaryLight;
  }
}

@keyframes draw {
  0%,
  100% {
    stroke-opacity: 1;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
