@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?t78vfl');
  src: url('../fonts/icomoon.eot?t78vfl#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?t78vfl') format('truetype'),
    url('../fonts/icomoon.woff?t78vfl') format('woff'),
    url('../fonts/icomoon.svg?t78vfl#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon', sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: 'liga';
  -moz-font-feature-settings: 'liga=1';
  -moz-font-feature-settings: 'liga';
  -ms-font-feature-settings: 'liga' 1;
  font-feature-settings: 'liga';
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-triangle2:before {
  content: '\e90a';
}
.icon-triangle:before {
  content: '\e909';
}
.icon-chat:before {
  content: '\e908';
  color: #fff;
}
.icon-social-linkedin:before {
  content: '\e907';
}
.icon-phone:before {
  content: '\e905';
}
.icon-search:before {
  content: '\e900';
}
.icon-keyboard_arrow_down:before {
  content: '\e901';
}
.icon-clear:before {
  content: '\e903';
}
.icon-close:before {
  content: '\e903';
}
.icon-location-pin:before {
  content: '\e902';
}
.icon-menu:before {
  content: '\e904';
}
.icon-whatsapp:before {
  content: '\e906';
}
.icon-phone1:before {
  content: '\e942';
}
.icon-facebook:before {
  content: '\ea90';
}
.icon-instagram:before {
  content: '\ea92';
}
.icon-twitter:before {
  content: 'twitter';
}
.icon-youtube:before {
  content: '\ea9d';
}
