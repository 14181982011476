/* stylelint-disable */
.ModalBill {
  input[type='text'] {
    margin-bottom: 28px;
  }

  .Modal-tickets {
    overflow: hidden;
  }

  &-prev,
  &-next {
    position: static;
    width: 55px;
    height: 51px;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $primaryAnil;
    border-radius: 5px;

    &.swiper-button-disabled {
      background: rgba(0, 80, 180, 0.58);
    }

    &:after {
      content: '\e901';
      font-size: $size-32;
      transform: rotate(-90deg);
      font-family: 'icomoon' !important;
    }
  }

  &-prev {
    &:after {
      transform: rotate(90deg);
    }
  }

  .swiper-wrapper-lock {
    .swiper-wrapper {
      transform: none !important;
    }

    + .ModalBill-wrapButtons {
      display: none;
    }
  }

  &-content {
    max-width: 500px;
    overflow: auto;
    max-height: 500px;

    .Modal-first {
      .g-recaptcha {
        margin-bottom: 28px;
      }
    }
  }

  &-show {
    visibility: visible;
    opacity: 1;

    .Modal-content {
      top: 45%;
    }
  }

  &-head {
    display: flex;
    font-size: 10px;
    line-height: 100%;
    margin-bottom: 5px;
    border: 1px solid #424242;
    justify-content: space-between;

    li {
      display: flex;
      align-items: center;
      width: 33%;
      margin-bottom: 0;
      flex-wrap: wrap;
      padding: 2px 0;
      border-right: 1px solid #424242;

      &:last-child {
        border: 0;
      }
    }

    cite {
      display: block;
      width: 100%;
      font-size: 9px;
      font-style: normal;
    }
  }

  &-title {
    line-height: 30px;
    color: $primaryAnil;
  }

  &-finish {
    margin-top: 51px !important;

    &--error {
      font-size: 20px !important;
      margin: 0 !important;
    }
  }

  input[type='submit'],
  button,
  a {
    margin: 0 auto 22px auto;
    width: auto;
  }

  .d-none {
    display: none;
  }

  &-wrapButtons {
    display: flex;
    gap: 10px;
    margin-bottom: 32px;
  }

  hr {
    border: 0;
    border-top: 2px solid #888;
    margin: 10px 0 20px 0;
    border-bottom: #888;
  }

  @media (min-width: $large) {
    &-title {
      line-height: 36px;
    }

    &-head {
      line-height: 110%;
      font-size: 12px;

      cite {
        font-size: 10px;
      }
    }

    &-finish--error {
      font-size: 24px !important;
    }
  }
}
