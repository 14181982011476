.ContactUs-404 {
  padding-top: 80px;
  padding-bottom: 40px;

  .ContactUs__Title {
    font-weight: 500;
    font-size: $size-28;
    line-height: 42px;
    text-align: center;
    color: $primaryDark;
    margin-top: 0;
  }

  .ContactUs__Cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .ContactUs__Card {
      width: 210px;
      background-color: #f2f2f2;
      padding: 16px;
      border-radius: 10px;
      margin: 16px 40px;
      min-height: 210px;

      .Card__Top {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .Card__Image {
        width: 40px;
        height: 40px;
      }

      .Card__Title {
        font-weight: 400;
        font-size: $size-16;
        line-height: $line-28;
        color: $primaryAnil;
        margin: 0;
        text-align: center;
      }

      .Card__Content {
        font-weight: 500;
        font-size: $size-14;
        line-height: $line-22;
        color: $grey;
        margin-top: 24px;
        margin-bottom: 16px;
      }

      .Card__Link {
        display: block;
        color: $primaryAnil;
        font-weight: 500;
        font-size: $size-14;
        line-height: $line-22;
        text-align: center;
        border: none;
        outline: none;
        box-shadow: none;
      }
    }
  }

  @media (min-width: $container) {
    .ContactUs__Title {
      font-size: $size-28;
      line-height: 42px;
      text-align: left;
    }

    .ContactUs__Cards {
      justify-content: flex-start;

      .ContactUs__Card {
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}
