.form-duvidas-container {
  align-items: center;
  background-color: $primaryAnil;
  display: flex;
  justify-content: center;
  padding: 0 10px;
  width: 100%;

  &-inner {
    color: white;
    display: flex;
    gap: 30px;
    max-width: 1170px;
    padding: 40px 0;
    width: 100%;

    &-left {
      display: flex;
      flex-direction: column;
      max-width: 412px;

      p {
        font-size: 28px !important;
        margin: 0;
      }

      span {
        font-size: 16px;
        margin-top: 30px;
      }

      @media screen and (max-width: 748px) {
        padding: 0 10px;
        max-width: 100%;
      }

      @media screen and (max-width: 1050px) {
        padding: 0 10px;
      }
    }

    &-right {
      align-items: center;
      display: flex;
      flex: 1 !important;

      form {
        align-items: center;
        justify-content: center;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        padding: 0 10px;

        input,
        select {
          padding: 10px 50px;
          width: 100%;
          border-radius: 10px;
          margin: 0;

          &::placeholder {
            color: #acacac !important;
          }
        }

        select option {
          color: black;
        }
        select:not(:checked) {
          color: gray;
        }

        .submit {
          align-items: center;
          border: 1px solid white;
          background: $primaryAnil;
          border-radius: 26px;
          color: white;
          cursor: pointer;
          display: flex;
          font-weight: 500;
          justify-content: center;
          line-height: 0;
          margin: 0;
          width: fit-content;
          min-width: 300px;
          padding: 20px;

          &:hover {
            background: white;
            color: #2271b1;
          }
        }

        @media screen and (max-width: 1150px) {
          flex-direction: column;
          width: 100%;
          gap: 20px;

          .submit {
            width: 100%;
          }
        }
      }
    }

    @media screen and (max-width: 748px) {
      flex-direction: column;
    }
  }
}
