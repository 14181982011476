.SectionTwoCards {
  .SectionTwoCards__Title {
    font-weight: 500;
    font-size: $size-16;
    line-height: $line-24;
    text-align: center;
    color: $primaryDark;
    max-width: 500px;
    margin: 35px auto;
  }

  .SectionTwoCards__Cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .Card {
      margin: 0 10px 48px 10px;
      padding-bottom: 30px;
      box-shadow: 5px 6px 9px rgba(0, 0, 0, 0.25);
      border-bottom-left-radius: 21px;
      border-bottom-right-radius: 21px;
      background-color: #0a4dbc;
      width: 272px;
      min-height: 575px;

      .Card__Image {
        width: 100%;
        height: 344px;
        object-fit: cover;
      }

      .Card__Title {
        font-weight: 700;
        font-size: $size-24;
        line-height: $line-26;
        color: #ececec;
        max-width: 211px;
        margin: 17px 21px 10px 21px;
      }

      .Card__Text {
        color: #ececec;
        font-weight: 500;
        font-size: $size-16;
        line-height: $line-20;
        max-width: 203px;
        margin-left: 21px;
        margin-bottom: 15px;
      }

      .Card__Button {
        border: none;
        outline: none;
        margin: auto;
        margin-top: 25px;
        display: block;
        color: $white;
        font-weight: 600;
        font-size: $size-14;
        line-height: $line-26;
        border-radius: 30px;
        background: radial-gradient(
          50% 50% at 50% 50%,
          $secondaryLighten 0,
          $secondaryLigh 100%
        );
        cursor: pointer;

        &:hover {
          background: radial-gradient(
            50% 50% at 50% 50%,
            $secondaryLighten 0%,
            $secondaryDark 100%
          );
        }

        a {
          display: block;
          transition-property: border, color;
          transition-duration: 0.05s;
          transition-timing-function: ease-in-out;
          padding: 7px 22px;
          border: none;
          outline: none;
          color: $white;
          box-shadow: none;
        }
      }
    }
  }

  @media (min-width: $container) {
    .SectionTwoCards__Title {
      font-size: $size-22;
      line-height: $line-28;
      max-width: 544px;
      margin: 50px auto 79px auto;
    }
  }
}
