/* stylelint-disable */
.scroll-lock {
  overflow: visible !important;
}
.Modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  background: rgba(#000, 0.6);
  text-rendering: geometricPrecision;
  z-index: 4;
  font-size: $size-12;
  line-height: $line-22;
  transition: 0.3s;

  &-tickets:empty + .Modal-groupButtons {
    display: none;
  }

  p {
    font-size: inherit;
    line-height: inherit;
    margin-bottom: 15px;
  }

  .select2-selection__choice + li:before {
    display: none;
  }

  .select2-search--inline {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      right: -27px;
      width: 20px;
      height: 20px;
      background: url('../images/arrow-down.svg') center no-repeat;
      transform: translateY(-50%);
    }
  }

  .charleft {
    display: none;
  }

  .gform-count {
    text-align: right;
    display: block;
  }

  &-show {
    opacity: 1;
    visibility: visible;
  }

  &-close {
    position: absolute;
    top: 30px;
    right: 30px;
    color: #424242;
    cursor: pointer;
    font-size: 21px;
    text-rendering: geometricPrecision;
  }

  label {
    display: block;
    color: $primaryAnil;
    font-weight: 400;
    margin-bottom: 8px;
    text-rendering: geometricPrecision;
  }

  &-overlay {
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: 1s;
  }

  &-iconSpin {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba($greyLight, 0.8);

    svg {
      opacity: 0.8;
    }
  }

  &-spin {
    .Modal-iconSpin {
      visibility: visible;
      opacity: 1;
    }
  }

  &-content {
    @extend %alignCenter;
    background: $white;
    padding: 42px 16px;
    border-radius: 10px;
    width: 90%;
    top: 45%;
    max-width: 640px;
    min-height: 460px;
    top: 35%;
    transition: top 1s;
  }

  &-loc {
    .Modal-content {
      min-height: 0;
    }

    .Modal-title {
      color: $primaryDark !important;
      font-weight: 500 !important;
    }

    .select2-container--default
      .select2-selection--multiple
      .select2-selection__choice {
      background: none;
      border: 0 none;
      border-radius: 0;
      margin: 0;
      padding: 0;
      position: static;
      max-width: 100%;
      overflow: hidden;
      text-overflow: unset;
      vertical-align: unset;
      white-space: normal;
      text-rendering: geometricPrecision;
      line-height: 120%;
    }

    .select2-container--default
      .select2-selection--multiple
      .select2-selection__choice__remove {
      position: absolute;
      top: 50%;
      right: 0;
      left: auto;
      font-size: 30px;
      line-height: 40px;
      color: $primary;
      font-weight: normal;
      padding: 0 20px;
      border: 0 !important;
      transform: translateY(-50%);

      &:hover {
        background: none;
      }
    }

    .select2-container .select2-search--inline .select2-search__field {
      font-family: $poppins;
      color: #3c434a;
      letter-spacing: 0.45px;
      text-rendering: geometricPrecision;
    }

    .select2-container--default.select2-container--focus
      .select2-selection--multiple {
      border: 2px solid $primaryAnil;
    }

    .select2-container--default .select2-search--inline .select2-search__field {
      height: auto;
      line-height: 120%;
    }

    li {
      margin-bottom: 0;
    }

    .select2-container--default .select2-search--inline .select2-search__field {
      margin: 0;
    }
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 $size-32 0;
  }

  &-close-icon {
    cursor: pointer;
  }

  & &-title {
    font-size: $size-24;
    margin: 0 0 $size-32 0;
    font-weight: 600;
    color: $primaryAnil;
    display: flex;
    padding: 0 20px;
    line-height: 120%;
    text-rendering: geometricPrecision;
    align-items: center;

    &-small {
      font-size: $size-20;
    }

    &-center {
      text-align: center;
      display: block;
    }

    &-spaceTop {
      margin-top: 30px;
    }

    &-without-margin {
      margin: 0;
    }

    .icon {
      font-size: $size-30;
      display: block;
      max-width: 35px;
      color: $primaryDark;
    }
  }

  dt {
    float: left;
    font-weight: 600;
    margin-right: 5px;
    text-rendering: geometricPrecision;
  }

  dd {
    margin-bottom: 2px;
  }

  select {
    width: 100%;
    height: 60px;
    border-radius: 10px;
    font-size: $size-14;
    font-weight: 400;
    letter-spacing: 0.45px;
    padding: 0 42px 0 18px;
    border: 2px solid $primaryAnil;
  }

  input[type='text'] {
    width: 100%;
    border: 1px solid #d0d0d0;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 10px 15px;
    text-transform: uppercase;
  }

  .gform_submission_error,
  .validation_message {
    display: none;
  }

  .gfield_error {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
      width: 20px;
      height: 20px;
      background: url(../images/alert.png) no-repeat;
    }

    input,
    textarea {
      background: #ffffff;
      border: 1px solid #ff1818;
      box-sizing: border-box;
      border-radius: 5px;
      padding-right: 25px;

      &:focus,
      &:active {
        border: 1px solid #ff1818;
        box-shadow: none;
      }
    }
  }

  input[type='submit'],
  button,
  a {
    background: radial-gradient(
      50% 50% at 50% 50%,
      $primaryLight 0%,
      $primary 100%
    );
    border-radius: 30px;
    border: 0;
    cursor: pointer;
    font-style: normal;
    font-weight: 600;
    padding: 10px 30px;
    display: block;
    text-align: center;
    font-size: $size-14;
    letter-spacing: 0.2px;
    line-height: 180%;
    color: $white;
    min-width: 200px;
    font-family: $poppins;
    text-rendering: geometricPrecision;
    margin: $size-32 auto 0 auto;

    &:hover {
      background: radial-gradient(
        50% 50% at 50% 50%,
        $primary 0%,
        $primaryDark 100%
      );
    }
  }

  &-error {
    background: #cecece;
    border: 1px solid #d3d3d3;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 13px 10px;
  }

  img {
    margin-bottom: 16px;
  }

  & &-success-button {
    padding: 10px 30px !important;
  }

  & &-groupButtons {
    display: flex;
    flex-direction: column;

    input[type='submit'],
    button,
    a {
      margin-bottom: 16px;
    }
  }

  &-alert {
    display: flex;
    gap: 10px;
    width: 99%;
    padding-right: 10px;

    i {
      color: $primaryAnil;
      font-style: normal;
      font-weight: bold;
    }
  }

  @media (min-width: $large) {
    font-size: $size-14;
    line-height: $line-22;

    &-content {
      padding: 42px;
    }

    select {
      font-size: 16px;
    }

    input[type='submit'],
    button,
    a {
      margin-right: 0;
      width: 35%;
    }

    & &-groupButtons {
      display: flex;
      flex-direction: row;
      gap: 16px;
      justify-content: space-between;

      input[type='submit'],
      button {
        padding: 0;
      }
    }
  }
}
