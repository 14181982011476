.BottomContent {
  color: #cdbfbf;
  letter-spacing: 0.3px;
  padding: 24px 2px 68px 2px;

  .Container {
    display: flex;
    gap: 25px;
    flex-direction: column;

    &:empty {
      background: #000;
    }
  }

  p {
    font-size: $size-14;
    line-height: $line-22;
    margin: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: inherit;
    margin: 0;
    line-height: 32px;
    font-weight: 600;
    text-rendering: geometricPrecision;
    font-size: $size-24;
  }

  @media (min-width: $large) {
    padding: 16px 0 68px 0;
  }
}
