.ReturnToHome {
  margin-top: 50px;
  text-align: center;

  .ReturnToHome__Title {
    font-weight: 600;
    font-size: $size-12;
    line-height: $line-18;
    color: $primaryDark;
    max-width: 287px;
    margin: auto;
    margin-bottom: 0;
  }

  .ReturnToHome__Image {
    margin: auto;
    width: 100%;
    object-fit: cover;
    max-width: 400px;
  }

  .ReturnToHome__Button {
    margin: 34px auto;
    display: block;
    background: radial-gradient(
      50% 50% at 50% 50%,
      $primaryLight 0%,
      $primary 100%
    );
    border-radius: 27px;
    border: none;
    outline: none;

    &:hover {
      background: radial-gradient(
        50% 50% at 50% 50%,
        $primaryLight 0%,
        $primaryDark 100%
      );
    }

    a {
      color: $white;
      font-size: $size-12;
      line-height: $line-18;
      font-weight: 700;
      padding: 9.5px 9px;
      display: block;
      border: none;
      outline: none;
      box-shadow: none;
      transition-property: border, background, color;
      transition-duration: 0.05s;
      transition-timing-function: ease-in-out;
    }
  }

  @media (min-width: $tablet) {
    .Container {
      display: flex;
      flex-direction: column;
    }

    .ReturnToHome__Title {
      font-size: $size-16;
      line-height: $line-24;
      max-width: unset;
      order: 2;
      margin-bottom: 24px;
    }

    .ReturnToHome__Image {
      margin-bottom: 30px;
      order: 1;
    }

    .ReturnToHome__Button {
      order: 3;
      margin-top: 0;
    }
  }
}
